import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import ReviewItem from "./ReviewItem";
import { Button, Spin, Tag, Checkbox, Select, Switch } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { hexToRgbA } from "util/common";
import AudienceFooter from "./AudienceFooter";
import { Option } from "antd/lib/mentions";
import "../../CustomSummary/customSummary.scss";
import { notification } from "antd";
import { apiRequest } from "util/services";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "components/General/ModalFormComponents";
import { Tooltip } from "antd";

const ReviewsList = ({
  allReviews,
  getHighlights,
  startIndex,
  setStartIndex,
  getAllReviews,
  getOnlyReviews,
  reviewsLoading,
  handleTagSelection,
  user,
  shared,
  numReviews,
  searchControllerRef,
  setReviewsLoading,
  boardDataSrc,
  topTopics,
  highlights,
  currentDashboardHash,
  setCreateTaskModal,
  setCreateModal,
  // facebookReplies,
  // getFacebookReplies,
  setNumOfDocs,
  numOfDocs,
  selectedPivots,
  setSelectedPivots,
  levelWisePivotSelections,
  setLevelWisePivotSelections,
  pivotOptions,
  multiLevelOptions,
  setFilters,
  filters,
  resetPivotFilters,
  resetTopicFilters,
  topicRatingsLoading,
  handleClearAllTopTopics,
  checkedTopicIds,
  setCheckedTopicIds,
  selectedTopicIds,
  setSelectedTopicIds,
  selectedTopicFromBars,
  setSelectedTopicFromBars,
  npsScale,
  setSelectedDecisions,
  cursors,
  setCursorNext,
  backWardCursors,
  disableSelection,
  setHideEmptyReviews,
  hideEmptyReviews,
  resetReviewsPage,
  setResetReviewsPage,
  selectedReviewIDs,
  setSelectedReviewIds,
  setTweetsCheckList,
  setUsersCheckList,
  tweetsCheckList,
  usersCheckList,
}) => {
  const searchState = useSelector(
    (state) => state.DashboardReducer.searchState
  );
  const insightSearch = useSelector(
    (state) => state.DashboardReducer?.insightBasedSearch
  );
  const platformId = useSelector(
    (state) => state.DashboardReducer.dashboard?.PlatformId
  );
  const userReducer = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [reviewPageNumber, setReviewPageNumber] = useState(1);
  const [showHidePopup, setShowHidePopup] = useState(false);
  const [showCustomTopicLabel, setShowCustomTopicLabel] = useState(true);
  const [showCustomTopicParentLabel, setShowCustomTopicParentLabel] = useState(
    true
  );
  const [showAiTopicLabel, setShowAiTopicLabel] = useState(false);
  const [showPivotsInReviews, setShowPivotsInReviews] = useState(true);
  const [showEmptyReviews, setShowEmptyReviews] = useState(false);
  const [selectionIsFromSameReview, setSelectionIsFromSameReview] = useState(
    null
  );
  const [prevDeletionKey, setPrevDeletionKey] = useState(null);
  const [showMobileTaskPopup, setShowMobileTaskPopup] = useState(false);

  const [enableEditFilters, setEnableEditFilters] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [displayFeedbackArea, setDisplayFeedbackArea] = useState(false);
  const [feedbackModeEnabled, setFeedbackModeEnabled] = useState(false);
  const [prevSelectionKey, setPrevSelectionKey] = useState(null);
  const [highlightedText, setHighlightedText] = useState({});
  const [allTopics, setAllTopics] = useState([]);
  const [levelWiseTopics, setLevelWiseTopics] = useState([]);
  const [deletedTopics, setDeletedTopics] = useState({});
  const [deletedIntents, setDeletedIntents] = useState({});
  const [publish, setPublish] = useState({});
  const [openCase, setOpenCase] = useState({});
  const [action, setAction] = useState({});
  const [prevClassifierKey, setPrevClassifierKey] = useState(null);
  const [decisionTags, setDecisionTags] = useState({});
  const [disableChecks, setDisableChecks] = useState(false);
  const [allReviewsIntents, setAllReviewsIntents] = useState({});
  const [pageChanged, setPageChanged] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showHidePopupRef = useRef();
  const mobileActionPopupRef = useRef();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [queryFilters, setQueryFilters] = useState({});
  const [closeDateFilter, setCloseDateFilter] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false); // to open the delete modal box for reviews
  const [deletingReview, setDeletingReview] = useState(false); // for loading icon till operation is done
  const [showExtraFilterOption, setShowExtraFilterOption] = useState(false); // Mulitple reviews select state
  const extraFilterRef = useRef(); //to synch the select and select all option

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "selected_topic" ||
          key === "active_topic_data" ||
          key === "decisionFilter" ||
          key === "child_others" ||
          key === "parent_others" ||
          key === "hotterm"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);
  useEffect(() => {
    if (userReducer.isFeedbackEnabled) {
      getFeedbackMode();
    }
  }, [userReducer.isFeedbackEnabled]);
  const getFeedbackMode = async () => {
    const url = "users/feedback_mode";
    const response = await apiRequest(url, "POST", {}, true);
    if (response?.success === true || response?.success === false) {
      setFeedbackModeEnabled(response.success);
    } else {
      notification.error({
        message: "Couldn't get the mode",
      });
      setFeedbackModeEnabled(false);
    }
  };
  const createLevelWiseTopics = (topics) => {
    if (topics && topics.length > 0) {
      let levelWiseTopics = [];

      function processChildren(children) {
        let result = [];
        children.forEach((child) => {
          let processedChild = {
            id: child.id,
            label: child.name,
            children: [],
          };
          if (child.hasOwnProperty("learning")) {
            processedChild.learning = child.learning;
          }
          if (child.child && child.child.length > 0) {
            processedChild.children = processChildren(child.child);
          }
          result.push(processedChild);
        });
        return result;
      }

      topics.forEach((topic) => {
        let level1Children = [];
        if (topic.child && topic.child.length > 0) {
          level1Children = processChildren(topic.child);
        }
        let topicStructure = {
          id: topic.id,
          label: topic.name,
          children: level1Children,
        };
        if (topic.hasOwnProperty("learning")) {
          topicStructure.learning = topic.learning;
        }
        levelWiseTopics.push(topicStructure);
      });

      return levelWiseTopics;
    }
  };
  const searchTopics = (topicsArr, id) => {
    for (let tpc of topicsArr) {
      if (tpc.id === parseInt(id, 10)) {
        return tpc;
      }
      if (tpc.child && tpc.child.length > 0) {
        const res = searchTopics(tpc.child, id);
        if (res) {
          return res;
        }
      }
    }
  };
  const isTopicLearningBased = (id) => {
    const foundTopic = searchTopics(topTopics.tree, id);
    if (
      foundTopic &&
      foundTopic.hasOwnProperty("learning") &&
      foundTopic.learning
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getAllTopics = async () => {
    // setCheckedTopicTagList([])
    const payload = {
      view: "tree",
      offset: 0,
      step: 0,
    };
    const response = await apiRequest("library/all", "POST", payload, true);
    if (response.success) {
      const topics = response.success.topics;
      setLevelWiseTopics(createLevelWiseTopics(topics));
      setAllTopics(response.success.dropdown_topic_list);
    } else {
      notification.error({
        message: response.error,
      });
    }
  };
  useEffect(() => {
    setLevelWiseTopics(createLevelWiseTopics(topTopics.tree));
  }, [topTopics]);

  useEffect(() => {
    setReviewPageNumber(parseInt(startIndex, 10) + 1);
  }, [startIndex]);
  const hasAlphabet = (str) => {
    return /[A-Za-z]/.test(str);
  };
  useEffect(() => {
    if (highlightedText) {
      setHighlightedText({});
    }
  }, [feedbackModeEnabled]);
  useEffect(
    () => {
      if (
        (highlightedText &&
          Object.values(highlightedText)?.flat().length > 0) ||
        (deletedTopics && Object.values(deletedTopics)?.flat().length > 0)
      ) {
        setDisableChecks(true);
      } else {
        setDisableChecks(false);
      }
    },
    [highlightedText, deletedTopics],
    disableChecks
  );
  // useEffect(() => {
  //   if (highlights && Object.keys(highlights).length > 0) {
  //     allReviews.map((review) => {
  //       const classifier = highlights[review?.date_gpid]
  //         ? highlights[review?.date_gpid]["classifier"]
  //         : {};
  //       setPublish((prev) => ({
  //         ...prev,
  //         [review?.date_gpid]:
  //           classifier && Object.keys(classifier).length > 0
  //             ? classifier.mPublish
  //             : null,
  //       }));
  //       setOpenCase((prev) => ({
  //         ...prev,
  //         [review?.date_gpid]:
  //           classifier && Object.keys(classifier).length > 0
  //             ? classifier.mOpenCase
  //             : null,
  //       }));
  //       setAction((prev) => ({
  //         ...prev,
  //         [review?.date_gpid]:
  //           classifier && Object.keys(classifier).length > 0
  //             ? classifier.mAction
  //             : null,
  //       }));
  //     });
  //   }
  // }, [highlights]);

  // function for deleting the reviews

  // const handleDeleteReview = async () => {
  //   //starts the loading state
  //   setDeletingReview(true);

  //   const payload = {
  //     dashboard_id: currentDashboardHash,
  //     review_ids: selectedReviewIDs,
  //   };

  //   const result = await apiRequest(
  //     "dashboards/delete_reviews",
  //     "POST",
  //     payload
  //   );

  //   if (result.error) {
  //     notification.error({
  //       message: result.error,
  //     });
  //   } else {
  //     setResetReviewsPage(!resetReviewsPage);
  //     //sets the selected reviews to null
  //     setSelectedReviewIds([]);
  //     //set loading state to false
  //     setDeletingReview(false);
  //     //closes the pop-up
  //     setDeleteModal(false);

  //     notification.success({
  //       message: "Reviews deleted successfully",
  //     });
  //   }
  // };
  useEffect(() => {
    if (allReviews) {
      setAllReviewsIntents({});
    }
  }, [allReviews]);
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };

  const reviews =
    allReviews && allReviews.length
      ? allReviews?.map((review, i) => {
          const targetIdentifier = review?.gpid;
          const sequences =
            highlights && highlights[review?.date_gpid]
              ? highlights[review?.date_gpid]["sequences"]
              : [];
          const classifierSequences =
            highlights && highlights[review?.date_gpid]
              ? highlights[review?.date_gpid]["classifier_sequences"]
              : [];
          const sent =
            highlights && highlights[review?.date_gpid]
              ? highlights[review?.date_gpid]["sentiment"]
              : "";
          const intnt =
            highlights && highlights[review?.date_gpid]
              ? highlights[review?.date_gpid]["intent"]
              : "";
          const associated_topics =
            highlights &&
            highlights[
              review?.date_gpid
            ] /*&&highlights[review?.date_gpid]["sequences"]*/
              ? highlights[review?.date_gpid]["associated_topics"]
              : [];
          return (
            <div className={styles.forAllReviewsStyle}>
              {!feedbackModeEnabled && targetIdentifier && !shared && (
                <span>
                  <Checkbox
                    checked={tweetsCheckList[targetIdentifier]}
                    className={styles.reviewsCheckbox}
                    onChange={() => {
                      handleCheckChange(review, targetIdentifier);
                    }}
                  />
                </span>
              )}
              <ReviewItem
                text={review?.text}
                title={review?.title}
                intents={
                  typeof intnt === "string" ? intnt.split(".")[0] : intnt
                }
                sequences={sequences}
                classifierSequences={classifierSequences}
                sentiment={sent}
                date={review?.timestamp}
                selectionIsFromSameReview={selectionIsFromSameReview}
                setSelectionIsFromSameReview={(value) =>
                  setSelectionIsFromSameReview(value)
                }
                allReviewsIntents={allReviewsIntents}
                setAllReviewsIntents={(val) => setAllReviewsIntents(val)}
                pageChanged={pageChanged}
                setPageChanged={(val) => setPageChanged(val)}
                relatedTopics={associated_topics}
                rate={review?.num_rating}
                shared={shared}
                getHighlights={getHighlights}
                allTopics={allTopics}
                levelWiseTopics={levelWiseTopics}
                setReviewsLoading={setReviewsLoading}
                displayFeedbackArea={displayFeedbackArea}
                currentDashboardHash={currentDashboardHash}
                internalId={review?.internalId}
                prevDeletionKey={prevDeletionKey}
                setPrevDeletionKey={(val) => setPrevDeletionKey(val)}
                prevSelectionKey={prevSelectionKey}
                setPrevSelectionKey={(newVal) => setPrevSelectionKey(newVal)}
                highlightedText={highlightedText}
                setHighlightedText={(val) => setHighlightedText(val)}
                deletedTopics={deletedTopics}
                setDeletedTopics={(val) => setDeletedTopics(val)}
                deletedIntents={deletedIntents}
                setDeletedIntents={(val) => setDeletedIntents(val)}
                Gpid={review?.gpid}
                date_gpid={review?.date_gpid}
                disableChecks={disableChecks}
                // facebookReplies={facebookReplies}
                // getFacebookReplies={getFacebookReplies}
                reviewPlatformId={review?.num_platform}
                // checkbox={
                //   targetIdentifier &&
                //   !shared && (
                //     <Checkbox
                //       checked={tweetsCheckList[targetIdentifier]}
                //       onChange={() =>
                //         handleCheckChange(review, targetIdentifier)
                //       }
                //     />
                //   )
                // }
                feedbackModeEnabled={feedbackModeEnabled ? true : false}
                publish={publish}
                openCase={openCase}
                action={action}
                setPublish={(val) => setPublish(val)}
                setOpenCase={(val) => setOpenCase(val)}
                setAction={(val) => setAction(val)}
                prevClassifierKey={prevClassifierKey}
                setPrevClassifierKey={(val) => setPrevClassifierKey(val)}
                name={review?.name}
                pic={review?.pic}
                userVerified={review?.UserVerified}
                username={review?.username}
                likes={review?.thumbsUpCount}
                restaurant={review?.pivot}
                boardDataSrc
                npsRating={parseInt(review?.nps)}
                npsScale={npsScale}
                showCustomTopicLabel={showCustomTopicLabel}
                engagements={{
                  likes: hasAlphabet(review?.likes)
                    ? review?.likes
                    : parseInt(review?.likes),
                  comments: hasAlphabet(review?.comment_count)
                    ? review?.comment_count
                    : parseInt(review?.comment_count),
                  retweets: hasAlphabet(review?.retweet_count)
                    ? review?.retweet_count
                    : parseInt(review?.retweet_count),
                  views: hasAlphabet(review?.views_count)
                    ? review?.views_count
                    : parseInt(review?.views_count),
                }}
                showPivotsInReviews={showPivotsInReviews}
                showAiTopicLabel={showAiTopicLabel}
                showCustomTopicParentLabel={showCustomTopicParentLabel}
                allReviews={allReviews}
                getOnlyReviews={getOnlyReviews}
                reviewPageNumber={reviewPageNumber}
                startIndex={parseInt(startIndex, 10)}
                decisionTags={decisionTags}
                setDecisionTags={(val) => setDecisionTags(val)}
                isTopicLearningBased={isTopicLearningBased}
                hideEmptyReviews={hideEmptyReviews}
              />
            </div>
          );
        })
      : [];

  const handleRemoveDecisionFilter = (key) => {
    const updatedDecs = params.get("decisionFilter")
      ? JSON.parse(params.get("decisionFilter"))
      : {};
    if (updatedDecs && Object.keys(updatedDecs)?.length > 0) {
      delete updatedDecs[key];
      setSelectedDecisions(updatedDecs);
      if (updatedDecs && Object.keys(updatedDecs)?.length > 0) {
        updateQParams("decisionFilter", JSON.stringify(updatedDecs));
      } else {
        params.delete("decisionFilter");
        history.push({ search: params.toString() });
      }
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          decisionFilter: updatedDecs,
        },
      });
    } else {
      setSelectedDecisions({});
      params.delete("decisionFilter");
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          decisionFilter: {},
        },
      });
    }
  };
  const handleRemovePivotFilter = (key, value) => {
    const updatedPvts = {
      ...(params.get("pivotFilter")
        ? JSON.parse(params.get("pivotFilter"))
        : {}),
    };
    const indexToRemove = updatedPvts[key].indexOf(value);
    // If the value exists, remove it
    if (indexToRemove !== -1) {
      updatedPvts[key].splice(indexToRemove, 1);
    }
    if (
      updatedPvts &&
      Object.keys(updatedPvts) &&
      Object.values(updatedPvts).flat().length > 0
    ) {
      if (updatedPvts[key] && updatedPvts[key].length === 0) {
        delete updatedPvts[key];
        setLevelWisePivotSelections((prev) => {
          const updt = { ...prev };
          delete updt[key];
          return updt;
        });
      }
      setSelectedPivots(updatedPvts);
      setLevelWisePivotSelections((prev) => {
        const updatedSelections = { ...prev };
        const removeEntries = (obj, k, v) => {
          Object.keys(obj).forEach((ke_y) => {
            if (ke_y === k && obj[ke_y] === v) {
              delete obj[ke_y];
            } else if (typeof obj[ke_y] === "object") {
              removeEntries(obj[ke_y], k, v);
            }
          });
        };
        removeEntries(updatedSelections, key, value);
        return updatedSelections;
      });
      updateQParams("pivotFilter", JSON.stringify(updatedPvts));
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          pivotFilter: updatedPvts,
        },
      });
    } else {
      setSelectedPivots({});
      setLevelWisePivotSelections({});
      params.delete("pivotFilter");
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          pivotFilter: {},
        },
      });
    }
  };
  const removeParentFromChildren = (parent) => {
    if (parent && parent !== "" && parent.includes(">")) {
      const substrs = parent.split(">").slice(-1)[0];
      return substrs;
    } else {
      return parent;
    }
  };
  const handleCloseRatingsTopic = (e, level, from) => {
    const topics = Object.values(selectedTopicFromBars).filter(
      (t) => t.level < level
    );
    setSelectedTopicFromBars(topics);
    for (let l = level; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        params.delete(`level${l}Selection`);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            [`selectedL${l}Topic`]: null,
          },
        });
      } else {
        break;
      }
      history.push({ search: params.toString() });
    }
  };
  const platformIdsANdNamesMap = {
    "1": t("feedback_dropdown_values.14"),
    "2": t("feedback_dropdown_values.15"),
    "4": t("feedback_dropdown_values.17"),
    "5": t("feedback_dropdown_values.18"),
    "6": "Instagram Hashtags",
    "9": t("feedback_dropdown_values.21"),
    "10": t("feedback_dropdown_values.22"),
    "14": t("feedback_dropdown_values.26"),
    "15": t("feedback_dropdown_values.27"),
    "17": t("feedback_dropdown_values.29"),
    "18": t("feedback_dropdown_values.30"),
    "19": t("feedback_dropdown_values.31"),
    "27": t("feedback_dropdown_values.36"),
    "29": t("feedback_dropdown_values.38"),
    "31": t("feedback_dropdown_values.40"),
    "32": t("feedback_dropdown_values.41"),
    "33": t("feedback_dropdown_values.42"),
    "34": t("feedback_dropdown_values.43"),
    "35": t("feedback_dropdown_values.44"),
  };
  const intentMapping = {
    "-1": t("feedback_dropdown_values.1"),
    "1": t("feedback_dropdown_values.5"),
    "2": t("feedback_dropdown_values.6"),
    "3": t("feedback_dropdown_values.7"),
    "4": t("feedback_dropdown_values.8"),
    "5": t("feedback_dropdown_values.9"),
    "6": t("feedback_dropdown_values.10"),
    "7": t("feedback_dropdown_values.11"),
    "8": t("feedback_dropdown_values.12"),
    "33": t("feedback_dropdown_values.13"),
  };
  const sentimentMapping = {
    "-1": t("feedback_dropdown_values.1"),
    "0.5": t("feedback_dropdown_values.3"),
    "0.0": t("feedback_dropdown_values.4"),
    "1.0": t("feedback_dropdown_values.2"),
    "33": t("feedback_dropdown_values.13"),
  };
  useEffect(() => {
    let data = [];
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      decisionFilter,
      sortByDate,
    } = queryFilters;
    if (top_topics?.length > 0) {
      const parent_ids = top_topics
        .map((item) => item?.parent_id)
        .filter((item) => {
          if (item) {
            return item;
          }
        });
      const topicsText = top_topics.map((item, index) => {
        if (parent_ids?.includes(item?.id)) {
          return;
        }
        return (
          <>
            {index !== 0 && (
              <span className={styles.separator}> {topics_operator} </span>
            )}{" "}
            <span className={styles.roundIcon}>
              {item?.type === "ai" ? (
                <img src={"/assets/bubble_chart_black.svg"} alt="" />
              ) : item?.child ? (
                <img src={"/assets/filter_black.svg"} alt="" />
              ) : (
                <img src={"/assets/custom_words_black.svg"} alt="" />
              )}
            </span>
            {item?.parent_id
              ? `${
                  top_topics.find((i) => i?.id === item?.parent_id)?.label
                } > ${item.label}`
              : item.label}
          </>
        );
      });
      data.push(
        <Tag
          key="topics"
          closable={!reviewsLoading && !disableSelection}
          onClose={closeAllTopTopics}
        >
          {topicsText}
        </Tag>
      );
    }
    if (pivotFilter && Object.keys(pivotFilter).length !== 0) {
      const pvtFilters = Object.entries(pivotFilter).flatMap(
        ([key, values]) => {
          return values.map((value) => ({ key: key, value: value }));
        }
      );
      if (pvtFilters && pvtFilters.length > 0) {
        const pivotFilterTag = pvtFilters.map((filter, index) => {
          return (
            <>
              {index !== 0 && (
                <span className={styles.separator}>
                  {" "}
                  {params.get("pivots_operator")}{" "}
                </span>
              )}{" "}
              {filter.key}
              {": "}
              {filter.value}
            </>
          );
        });
        data.push(
          <Tag
            key="pivots"
            closable={!reviewsLoading && !disableSelection}
            onClose={resetPivotFilters}
          >
            {pivotFilterTag}
          </Tag>
        );
      }
    }
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        data.push(
          <Tag
            key={levelSelection}
            closable={!topicRatingsLoading}
            onClose={(e) => handleCloseRatingsTopic(e, level, "dash_page")}
          >
            {removeParentFromChildren(levelSelection?.label)}
          </Tag>
        );
      } else {
        break;
      }
    }
    if (keyword && keyword.length > 0) {
      const keys = keyword.map((keyword, i) => {
        return (
          <Tag
            key={keyword.label}
            closable={!reviewsLoading && !disableSelection}
            onClose={(e) => {
              handleCloseExtraContent(e, keyword.label);
            }}
          >
            <span className={styles.roundIcon}>
              {keyword.type === "search" ? (
                <img src={"/assets/search_black.svg"} alt="" />
              ) : (
                <img src={"/assets/top_words_black.svg"} alt="" />
              )}
            </span>
            {keyword.label}
          </Tag>
        );
      });
      data.push([...keys]);
    }
    if (sentiment) {
      let sentimentTxt, sentimentBG, sentimentFC;
      if (parseFloat(sentiment) === 1) {
        sentimentTxt = t("feedback_dropdown_values.2");
        sentimentBG = hexToRgbA("#00FF00", 0.1);
        sentimentFC = "green";
      } else if (parseFloat(sentiment) === 0.5) {
        sentimentTxt = t("feedback_dropdown_values.3");
        sentimentBG = hexToRgbA("#FFFF00", 0.1);
        sentimentFC = "yellow";
      } else if (parseFloat(sentiment) === 0) {
        sentimentTxt = t("feedback_dropdown_values.4");
        sentimentFC = "red";
        sentimentBG = hexToRgbA("#FF0000", 0.1);
      } else if (parseInt(sentiment) === 33) {
        sentimentTxt = t("feedback_dropdown_values.13");
        sentimentBG = hexToRgbA("#44FF00", 0.1);
        sentimentFC = "white";
      } else {
        sentimentTxt = t("feedback_dropdown_values.1");
        sentimentBG = hexToRgbA("#FFFF00", 0.1);
        sentimentFC = "yellow";
      }
      data.push(
        <Tag
          key={sentiment}
          style={{ background: sentimentBG, color: sentimentFC }}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("sentiment");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenSentiment");
          }}
        >
          {sentimentTxt}
        </Tag>
      );
    }
    if (match_date) {
      data.push(
        <Tag
          key={match_date}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("match_date");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenDate");
          }}
        >
          {match_date}
        </Tag>
      );
    }
    if (since && until) {
      data.push(
        <Tag
          key={[since, until]}
          closable={!reviewsLoading && !disableSelection}
          onClose={() => setCloseDateFilter(true)}
        >
          <span className={styles.roundIcon}>
            <img src={"/assets/calendar_filter.svg"} alt="" />
          </span>
          from {since} to {until}
        </Tag>
      );
    }
    if (intent) {
      data.push(
        <Tag
          key={intent}
          style={{
            background: "rgba(0, 110, 255, 0.1)",
            color: "rgb(0, 183, 255)",
          }}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("intent");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenIntent");
          }}
        >
          {intentMapping[intent]}
        </Tag>
      );
    }
    if (hotterm?.term) {
      data.push(
        <Tag
          key={hotterm.term}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("hotterm");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenHotTerm");
          }}
        >
          <span role="img" aria-label="hot-term" className={styles.roundIcon}>
            🔥
          </span>
          {hotterm.term}
        </Tag>
      );
    }
    if (rating) {
      data.push(
        <Tag
          key={rating}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("rating");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenDocRating");
          }}
        >
          {rating} stars
        </Tag>
      );
    }
    if (platform_id) {
      data.push(
        <Tag
          key={platformIdsANdNamesMap[platform_id]}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("platform_id");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenPlatformName");
          }}
        >
          {platformIdsANdNamesMap[platform_id]}
        </Tag>
      );
    }
    if (nps) {
      data.push(
        <Tag
          key={nps}
          closable={!reviewsLoading && !disableSelection}
          onClose={(e) => {
            params.delete("nps");
            history.push({ search: params.toString() });
            handleCloseExtraContent(e, false, "chosenNPS");
          }}
        >
          {nps}
        </Tag>
      );
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      Object.entries(decisionFilter).map(([key, value]) => {
        data.push(
          <Tag
            key={key}
            closable={!reviewsLoading && !disableSelection}
            onClose={() => handleRemoveDecisionFilter(key)}
          >
            {key === "publish"
              ? `Publish: ${value}`
              : key === "opencase"
              ? `Open Case: ${value}`
              : `Take Action: ${value}`}
          </Tag>
        );
      });
    }
    setFilters(data);
  }, [
    queryFilters,
    insightSearch,
    reviewsLoading,
    topicRatingsLoading,
    disableSelection,
  ]);

  const handleCloseExtraContent = (e, keyword, chosen) => {
    e.preventDefault();

    if (keyword) {
      handleTagSelection(keyword, false, true);
      return;
    }

    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        [chosen]: "",
      },
    });
  };

  const clearDateFilter = (chosen) => {
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        [chosen]: "",
      },
    });
  };
  const handleTopTopicFilterClear = (id) => {
    if (document.getElementById(id)) {
      const nestedLibs = getNestedLibsOfParentLib(topTopics.tree, id);
      setCheckedTopicIds(
        checkedTopicIds.filter((item) => ![...nestedLibs, id].includes(item.id))
      );
      setSelectedTopicIds(
        selectedTopicIds.filter(
          (item) => ![...nestedLibs, id].includes(item.id)
        )
      );
      document.getElementById(id).checked = false;
      document.getElementById(id).indeterminate = false;
    } else {
      const lib = getLibFromTopicId(id);
      if (document.getElementById(lib.lib_id)) {
        const nestedLibs = getNestedLibsOfParentLib(topTopics.tree, lib.lib_id);
        setCheckedTopicIds(
          checkedTopicIds.filter(
            (item) => ![...nestedLibs, lib.lib_id].includes(item.id)
          )
        );
        setSelectedTopicIds(
          selectedTopicIds.filter(
            (item) => ![...nestedLibs, lib.lib_id].includes(item.id)
          )
        );
        document.getElementById(lib.lib_id).checked = false;
      }
    }
  };
  const getNestedLibsOfParentLib = (topicsArr, id) => {
    const childLibs = [];
    const parentLib = topicsArr.find((topic) => topic.lib_id === id);
    if (parentLib && parentLib.child && parentLib.child.length > 0) {
      for (let childTpc of parentLib.child) {
        childLibs.push(childTpc.lib_id);
        getNestedLibsOfParentLib(parentLib.child, childTpc.lib_id);
      }
    }
    return childLibs;
  };

  const getLibFromTopicId = (id) => {
    const lib = searchTopics(topTopics.tree, id);
    if (lib) {
      return lib;
    }
    return null;
  };

  const closeAllTopTopics = () => {
    handleClearAllTopTopics();
    params.delete("top_topics");
    params.delete("topics_operator");
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenTopTopic: [],
      },
    });
  };
  const handleCloseTopics = (id) => {
    let filterID = queryFilters?.top_topics?.filter((item) => item.id === id);
    if (filterID.length !== 0) {
      filterID.forEach((item) => {
        if (
          item.type === "ai" ||
          (Object.keys(item).length === 3 && item.type === "custom")
        ) {
          return;
        } else {
          handleTopTopicFilterClear(id);
        }
      });
      params.delete("top_topics");
      params.append(
        "top_topics",
        JSON.stringify(
          queryFilters?.top_topics?.filter((item) => item.id !== id)
        )
      );
      history.push({ search: params.toString() });
      if (
        queryFilters?.top_topics?.filter((item) => item.id !== id)?.length === 0
      ) {
        params.delete("top_topics");
        params.delete("topics_operator");
      }
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: searchState?.chosenTopTopic?.filter(
            (item) => item.id !== id
          ),
        },
      });
    }
  };

  const handleCloseCustomTopic = (id) => {
    let filterID = searchState?.chosenCustomTopic?.filter(
      (item) => item.id === id
    );
    if (filterID.length !== 0) {
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenCustomTopic: searchState?.chosenCustomTopic?.filter(
            (item) => item.id !== id
          ),
        },
      });
    }
  };
  // Selecting Reviews one by one
  const handleCheckChange = (item, targetIdentifier) => {
    if (selectedReviewIDs.includes(item.date_gpid)) {
      setSelectedReviewIds((prev) => {
        let reviewIds = prev.filter((key) => key !== item.date_gpid);
        return reviewIds;
      });
    } else {
      setSelectedReviewIds((prev) => {
        return [...prev, item.date_gpid];
      });
    }
    setTweetsCheckList({
      ...tweetsCheckList,
      [targetIdentifier]: !tweetsCheckList[targetIdentifier],
    });
    if (usersCheckList[targetIdentifier]) {
      const data = usersCheckList;
      delete data[targetIdentifier];
      setUsersCheckList(data);
    } else {
      setUsersCheckList({
        ...usersCheckList,
        [targetIdentifier]: item,
      });
    }
  };

  //Select All Reviews
  const selectAll = () => {
    if (!allReviews) return;

    allReviews.forEach((item) => {
      const targetIdentifier = item?.gpid;

      setSelectedReviewIds((prev) => [
        ...new Set([...prev, item.date_gpid]), // Keeps existing IDs and adds the new one if not present
      ]);

      setTweetsCheckList((prev) => ({
        ...prev,
        [targetIdentifier]: true, // Always set to true instead of toggling
      }));

      setUsersCheckList((prev) => ({
        ...prev,
        [targetIdentifier]: item, // Always add the item (never remove)
      }));
    });
  };

  useEffect(() => {
    if (pageChanged) getOnlyReviews();
  }, [pageChanged]);

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     //Skipping the intial render
  //     isInitialRender.current = false;
  //     return;
  //   }
  //   getOnlyReviews();
  // }, [hideEmptyReviews]);

  useEffect(() => {
    if (params.has("page_offset") && Object.keys(queryFilters).length > 0) {
      setSelectedPerPage(parseInt(queryFilters.page_offset, 10));
    }
  }, [queryFilters]);
  const handlePageChange = (value) => {
    setSelectedPerPage(value);
    setNumOfDocs(value);
    setStartIndex(0);
    updateQParams("start_index", 0);
    updateQParams("page_offset", value);
    setReviewPageNumber(1);
  };
  const handleUserPage = (e) => {
    if (e.which === 13) {
      if (!reviewPageNumber) {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      } else if (
        parseInt(reviewPageNumber, 10) > 0 &&
        parseInt(reviewPageNumber, 10) <=
          Math.ceil(numReviews / selectedPerPage) &&
        /^\d+$/.test(parseInt(reviewPageNumber, 10))
      ) {
        setPageChanged(true);
        updateQParams("start_index", parseInt(reviewPageNumber, 10) - 1);
        e.target.blur();
      } else {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      }
    }
  };

  const handleResetFilter = () => {
    resetPivotFilters();
    resetTopicFilters();
    handleClearAllTopTopics();
    [
      "sentiment",
      "intent",
      "since",
      "until",
      "daysRange",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "level1Selection",
      "level2Selection",
      "level1OfSelected",
      "level2OfSelected",
      "decisionFilter",
      "cursor_next",
      "cursor_curr",
      "cursor_before",
      "disableSelectionID",
      "child_others",
      "parent_others",
    ].forEach((item) => params.delete(item));
    params.append("daysRange", "allTime");
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenKeywords: [],
        chosenSentiment: "",
        chosenDate: "",
        chosenFetchType: "topic",
        sortByDate: false,
        chosenIntent: "",
        chosenHotTerm: "",
        chosenDocRating: "",
        showNewReviewsLabel: false,
        chosenRestaurantName: "",
        chosenPlatformName: "",
        chosenTopTopic: [],
        chosenNPS: "",
        dateRange: [],
        pivotFilter: {},
        selectedL1Topic: "",
        selectedL2Topic: "",
        level1OfSelectedTopic: "",
        level2OfSelectedTopic: "",
        decisionFilter: {},
      },
    });
  };

  const showHidePopupOutClick = (e) => {
    if (
      (showHidePopupRef.current &&
        !showHidePopupRef.current.contains(e.target)) ||
      (mobileActionPopupRef.current &&
        !mobileActionPopupRef.current.contains(e.target))
    ) {
      setShowHidePopup(false);
      setShowMobileTaskPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", showHidePopupOutClick, true);
    return () =>
      document.removeEventListener("click", showHidePopupOutClick, true);
  }, []);

  const toggleFeedbackMode = async () => {
    if (feedbackModeEnabled) {
      const url = "users/feedback_mode/setup";
      const payload = { isFeedbackModeOn: false };
      const result = await apiRequest(url, "POST", payload, true);
      if (result?.success) {
        setFeedbackModeEnabled(false);
        setSwitchLoading(false);
      } else {
        notification.error({
          message: result?.error,
        });
        setSwitchLoading(false);
      }
    } else {
      const url = "users/feedback_mode/setup";
      const payload = { isFeedbackModeOn: true };
      const result = await apiRequest(url, "POST", payload, true);
      if (result?.success) {
        setFeedbackModeEnabled(true);
        setSwitchLoading(false);
      } else {
        notification.error({
          message: result?.error,
        });
        setSwitchLoading(false);
      }
    }
  };

  //For Select All feature
  const filterOutClick = (e) => {
    if (extraFilterRef.current && !extraFilterRef.current.contains(e.target)) {
      setShowExtraFilterOption(false);
    }
  };
  //For Select All feature
  useEffect(() => {
    document.addEventListener("click", filterOutClick, true);
    return () => document.removeEventListener("click", filterOutClick, true);
  }, []);

  // Empty the selected reviews when feedback mode enabled....
  useEffect(() => {
    setSelectedReviewIds([]);
    setTweetsCheckList({});
    setUsersCheckList({});
  }, [feedbackModeEnabled]);

  return (
    <div className={styles.mainWindow} id="ReviewsList">
      <Spin style={{ margin: "20px auto" }} spinning={reviewsLoading}>
        {!shared && (
          <div className={styles.titleContainer}>
            <div className={styles.headerLabelsOption}>
              {selectedReviewIDs && selectedReviewIDs.length ? (
                <div
                  className={styles.firstItem}
                  onClick={() =>
                    setShowExtraFilterOption(!showExtraFilterOption)
                  }
                >
                  <div className={styles.wrapper}>
                    <input
                      type="checkbox"
                      checked={selectedReviewIDs?.length > 0 ? true : false}
                      onChange={() => {}}
                      className={`${styles.topicCheckBox} ${
                        selectedReviewIDs?.length !== allReviews.length
                          ? styles.fewSelectedState
                          : undefined
                      }`}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <i className="fa-sharp fa-solid fa-caret-down"></i>
                  </div>
                  {t("label.11")} ({selectedReviewIDs.length})
                  {showExtraFilterOption && (
                    <div
                      className={styles.extraFilterOption}
                      // ref={extraFilterRef}
                    >
                      <span
                        onClick={() => {
                          setSelectedReviewIds([]);
                          setTweetsCheckList({});
                          setUsersCheckList({});
                          setShowExtraFilterOption(false);
                        }}
                      >
                        {t("topic_library.25")}
                      </span>
                      <span
                        onClick={() => {
                          selectAll();
                        }}
                      >
                        {t("topic_library.26")}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <div className={styles.title}>
                {allReviews && allReviews.length > 0 && (
                  <div className={styles.mobileViewActionBlock}>
                    <button
                      className={styles.mobileActionBtn}
                      onClick={() => setShowMobileTaskPopup(true)}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>

                    {showMobileTaskPopup && !disableSelection && (
                      <div
                        className={styles.mobileActionPopup}
                        ref={mobileActionPopupRef}
                      >
                        <span
                          onClick={() => {
                            setShowMobileTaskPopup(false);
                            setCreateTaskModal(true);
                          }}
                        >
                          {t("review.2")}
                        </span>
                        <span
                          onClick={() => {
                            setShowMobileTaskPopup(false);
                            setCreateModal(true);
                          }}
                        >
                          {t("selected_dashboard_page.65")}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {allReviews && allReviews?.length > 0 && (
                  <div className={styles.showHideTopicLabel}>
                    <span
                      className={styles.showHidePopupBtn}
                      onClick={() => setShowHidePopup(true)}
                    >
                      <EyeOutlined className={styles.eye} />
                      <span className={styles.showText}></span>
                    </span>
                    {showHidePopup && (
                      <div
                        className={styles.showTopicPopup}
                        ref={showHidePopupRef}
                      >
                        <span className={styles.action}>
                          <span>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/bubble_chart.svg"
                                  : "/assets/bubble_chart_black.svg"
                              }
                              width={22}
                              height={22}
                              alt=""
                            />
                            {t("selected_dashboard_page.156")}
                          </span>
                          {!showAiTopicLabel ? (
                            <EyeInvisibleOutlined
                              onClick={() => setShowAiTopicLabel(true)}
                              className={styles.eyeClose}
                            />
                          ) : (
                            <EyeOutlined
                              className={styles.eye}
                              onClick={() => setShowAiTopicLabel(false)}
                            />
                          )}
                        </span>
                        <span className={styles.action}>
                          <span>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/custom_words.svg"
                                  : "/assets/custom_words_black.svg"
                              }
                              width={20}
                              height={20}
                              alt=""
                            />
                            {t("selected_dashboard_page.116")}
                          </span>
                          {!showCustomTopicLabel ? (
                            <EyeInvisibleOutlined
                              onClick={() => setShowCustomTopicLabel(true)}
                              className={styles.eyeClose}
                            />
                          ) : (
                            <EyeOutlined
                              className={styles.eye}
                              onClick={() => setShowCustomTopicLabel(false)}
                            />
                          )}
                        </span>
                        <span className={styles.action}>
                          <span>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/relation.svg"
                                  : "/assets/relation_black.svg"
                              }
                              width={20}
                              height={20}
                              alt=""
                            />
                            {t("selected_dashboard_page.155")}
                          </span>
                          {!showCustomTopicParentLabel ? (
                            <EyeInvisibleOutlined
                              onClick={() =>
                                setShowCustomTopicParentLabel(true)
                              }
                              className={styles.eyeClose}
                            />
                          ) : (
                            <EyeOutlined
                              className={styles.eye}
                              onClick={() =>
                                setShowCustomTopicParentLabel(false)
                              }
                            />
                          )}
                        </span>

                        <span className={styles.action}>
                          <span>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/open_box_black.svg"
                                  : "/assets/open_box.svg"
                              }
                              width={20}
                              height={20}
                              alt=""
                            />
                            {t("selected_dashboard_page.162")}
                          </span>
                          {hideEmptyReviews ? (
                            <EyeInvisibleOutlined
                              onClick={() => setHideEmptyReviews(false)}
                              className={styles.eyeClose}
                            />
                          ) : (
                            <EyeOutlined
                              className={styles.eye}
                              onClick={() => setHideEmptyReviews(true)}
                            />
                          )}
                        </span>
                        {/* Code Ended */}
                        {pivotOptions && Object.keys(pivotOptions).length > 0 && (
                          <span className={styles.action}>
                            <span>
                              <img
                                src={
                                  theme === "dark"
                                    ? "/assets/pivot.svg"
                                    : "/assets/pivot_black.svg"
                                }
                                width={20}
                                height={20}
                                alt=""
                              />
                              {t("selected_dashboard_page.117")}
                            </span>
                            {!showPivotsInReviews ? (
                              <EyeInvisibleOutlined
                                onClick={() => setShowPivotsInReviews(true)}
                                className={styles.eyeClose}
                              />
                            ) : (
                              <EyeOutlined
                                className={styles.eye}
                                onClick={() => setShowPivotsInReviews(false)}
                              />
                            )}
                          </span>
                        )}
                        {/* <span className={styles.action}>
                        <span>
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/empty_reviews.svg"
                                : "/assets/empty_reviews_black.svg"
                            }
                            width={20}
                            height={20}
                            alt=""
                          />
                          {t("selected_dashboard_page.158")}
                        </span>
                        {!showEmptyReviews ? (
                          <EyeInvisibleOutlined
                            onClick={() => {
                              updateQParams("start_index", 0);
                              // setPageChanged(true)
                              updateQParams("include_empty_reviews", true);
                              setShowEmptyReviews(true);
                            }}
                            className={styles.eyeClose}
                          />
                        ) : (
                          <EyeOutlined
                            className={styles.eye}
                            onClick={() => {
                              updateQParams("start_index", 0);
                              // setPageChanged(true)
                              updateQParams("include_empty_reviews", false);
                              setShowEmptyReviews(false);
                            }}
                          />
                        )}
                      </span> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {userReducer.isFeedbackEnabled && (
              <div className={styles.feedbackMode}>
                <span className={styles.feedback}>
                  {feedbackModeEnabled
                    ? t("selected_dashboard_page.154")
                    : t("selected_dashboard_page.153")}{" "}
                  <Switch
                    loading={switchLoading}
                    checked={feedbackModeEnabled}
                    onClick={() => {
                      setSwitchLoading(true);
                      toggleFeedbackMode();
                    }}
                    size="small"
                  />
                </span>
              </div>
            )}
          </div>
        )}
        {filters.length > 0 && (
          <div className={styles.searchFilters}>
            <div className={styles.filterFirstRow}>{filters}</div>
            <div
              className={styles.reset}
              onClick={() => {
                !reviewsLoading && handleResetFilter();
              }}
            >
              {t("selected_dashboard_page.5")}
            </div>
          </div>
        )}
        <div className={styles.reviews}>
          {!searchControllerRef?.current && boardDataSrc ? (
            <Button
              id="load-reviews"
              type="primary"
              onClick={() => getAllReviews(false)}
            >
              {t("review.3")}
            </Button>
          ) : allReviews?.length === 0 ? (
            <div className={styles.emptyState}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/review_empty_state.svg"
                    : "/assets/review_empty_state_black.svg"
                }
                alt=""
              />
              <div className={styles.emptyStateContent}>
                {t("notifications.86")}
              </div>
            </div>
          ) : (
            reviews
          )}
        </div>
        <div className={styles.paginationWrapper}>
          <div className={styles.innerWrapper}>
            {allReviews?.length > 0 && (
              <div className={styles.paginationBox}>
                <Button
                  disabled={parseInt(startIndex, 10) === 0 || !reviewPageNumber}
                  onClick={() => {
                    // handleNextPrevPage(-1);
                    setPageChanged(true);
                    if (
                      parseInt(reviewPageNumber, 10) ===
                      parseInt(startIndex, 10) + 1
                    ) {
                      updateQParams(
                        "start_index",
                        parseInt(startIndex, 10) - 1
                      );
                      setCursorNext(params.get("cursor_before"));
                      updateQParams("cursor_curr", params.get("cursor_before"));
                    }
                    // parseInt(reviewPageNumber, 10) === startIndex + 1 && setReviewPageNumber(prev => parseInt(prev, 10) - 1)
                    // mixpanel.track("TOPIBOARD_REVIEWS_PAGE_PREV");
                  }}
                >
                  <i className="fa fa-angle-left" />
                </Button>
                <span className={styles.pagination}>
                  {/* <input
                    className={styles.pageIndex}
                    onKeyPress={handleUserPage}
                    type="text"
                    value={reviewPageNumber}
                    onChange={(e) => setReviewPageNumber(e.target.value)}
                  /> */}
                  {/* Page {parseInt(startIndex, 10) + 1}{" "} */}
                  {numReviews
                    ? ` ${reviewPageNumber} / ${Math.ceil(
                        numReviews / selectedPerPage
                      )}`
                    : ""}
                </span>
                <Tooltip
                  title={
                    hideEmptyReviews && cursors.next == null
                      ? t("dashboard_tooltips.pagination.1")
                      : ""
                  }
                >
                  <Button
                    disabled={
                      allReviews.length === 0 ||
                      (numReviews &&
                        parseInt(startIndex, 10) + 1 ===
                          Math.ceil(numReviews / selectedPerPage)) ||
                      !reviewPageNumber ||
                      cursors.next == null
                    }
                    onClick={() => {
                      // handleNextPrevPage(1);
                      setPageChanged(true);
                      if (
                        parseInt(reviewPageNumber, 10) ===
                        parseInt(startIndex, 10) + 1
                      ) {
                        updateQParams(
                          "start_index",
                          parseInt(startIndex, 10) + 1
                        );
                        setCursorNext(params.get("cursor_next"));
                        updateQParams("cursor_curr", params.get("cursor_next"));
                      }
                      // parseInt(reviewPageNumber, 10) === startIndex + 1 && setReviewPageNumber(prev => parseInt(prev, 10) + 1)
                      // mixpanel.track("TOPIBOARD_REVIEWS_PAGE_NEXT");
                    }}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                </Tooltip>
              </div>
            )}

            {numReviews > 0 && (
              <Select
                className="review-page-number-selector"
                value={selectedPerPage + ` / ${t("label.10")}`}
                onChange={handlePageChange}
                style={{ backgroundColor: "transparent" }}
              >
                <Option value={10}>10</Option>
                <Option disabled={numReviews < 25 ? true : false} value={25}>
                  25
                </Option>
                <Option disabled={numReviews < 50 ? true : false} value={50}>
                  50
                </Option>
                <Option disabled={numReviews < 100 ? true : false} value={100}>
                  100
                </Option>
              </Select>
            )}
          </div>

          {numReviews > 0 && (
            <div className={styles.reviewsTotal}>
              {selectedPerPage * parseInt(startIndex, 10) +
                allReviews?.length +
                " of " +
                numReviews}
            </div>
          )}
        </div>
      </Spin>
      {!shared && (
        <AudienceFooter
          setResetReviewsPage={setResetReviewsPage}
          resetReviewsPage={resetReviewsPage}
          deleteModal={deleteModal}
          deletingReview={deletingReview}
          currentDashboardHash={currentDashboardHash}
          setDeleteModal={setDeleteModal}
          setDeletingReview={setDeletingReview}
          setSelectedReviewIds={setSelectedReviewIds}
          selectedReviewIDs={selectedReviewIDs}
          usersCheckList={usersCheckList}
          setUsersCheckList={setUsersCheckList}
          setTweetsCheckList={setTweetsCheckList}
          user={user}
        />
      )}
      {closeDateFilter && (
        <Modal
          showModal={closeDateFilter}
          onClose={() => {
            setCloseDateFilter(false);
            setFilters((prevFilters) => {
              return [
                ...prevFilters,
                <Tag
                  key={[params.get("since"), params.get("until")]}
                  closable={!reviewsLoading && !disableSelection}
                  onClose={() => setCloseDateFilter(true)}
                >
                  <span className={styles.roundIcon}>
                    <img src={"/assets/calendar_filter.svg"} alt="" />
                  </span>
                  from {params.get("since")} to {params.get("until")}
                </Tag>,
              ];
            });
          }}
          hideCloseIcon={"true"}
          onSave={() => {
            params.delete("since");
            params.delete("until");
            params.delete("daysRange");
            params.append("daysRange", "allTime");
            history.push({ search: params.toString() });
            clearDateFilter("dateRange");
            clearDateFilter("selectedDaysRange");
            setCloseDateFilter(false);
          }}
          showSaveButton={true}
          showCancelButton={true}
          textonButton={t("selected_dashboard_page.142")}
          cancelButtonText={t("selected_dashboard_page.143")}
          titleAsset=""
          title={t("selected_dashboard_page.141")}
          widthofmodal="460px"
        >
          <p>{t("selected_dashboard_page.144")}</p>
          <p>{t("selected_dashboard_page.145")}</p>
        </Modal>
      )}
    </div>
  );
};

export default ReviewsList;
