import React, { useEffect, useState, useRef } from "react";
import { Badge, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../FirebaseConfig.js";
import { UNLOGGED, LOGGED } from "../util/constants.js";
import * as actions from "redux/popup/actions.js";
import { useDispatch } from "react-redux";
import Popup from "./Popup/index.js";
import { SET_USER } from "redux/user/actions.js";
import { apiRequest } from "util/services.js";
import mixpanel from "mixpanel-browser";
import { Avatar } from "antd";
import "./topMenu.scss";
import { themeColor } from "util/common.js";
import NotificationPage from "components/Notifcation/index.js";
import { useTranslation } from "react-i18next";
import i18n from "i18next.js";
import SystemNotification from "components/SystemNotification/index.js";
import { Button, notification, Tooltip } from "antd";
import { copyToClipboard } from "util/common";

const { SubMenu } = Menu;

const TopMenu = ({ authenticated, user, theme }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState([]);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [showKCenterPass, setshowKCenterPass] = useState(false);
  const [filterUnread, setFilterUnread] = useState(2);
  const [notificationList, setNotificationList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoUnreadAvailable, setShowNoUnreadAvailable] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [showLast30Days, setShowLast30Days] = useState(false);
  const [notifsCount, setNotifsCount] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const getAllNotification = async () => {
    setLoading(true);
    const payload = {
      user_id: user?.uid,
      filter_unread: filterUnread,
    };

    const response = await apiRequest(
      "notice/notification/all",
      "POST",
      payload,
      true
    );
    if (response?.notifications || response?.success) {
      if (response?.success) {
        setShowNoUnreadAvailable(true);
        setTempList([]);
        setNotificationList([]);
      } else {
        if (response?.notifications?.length === 0) {
          setShowEmptyState(true);
          setTempList([]);
          setNotificationList([]);
          setNotifsCount(0);
        } else {
          setShowEmptyState(false);
          setNotificationList(response.notifications);
          setNotifsCount(
            response.notifications.filter((item) => item.status === 0)?.length
          );
          if (response.notifications?.length > 5 && !showLast30Days) {
            let arr = [];
            for (let i = 0; i < 5; i++) {
              arr.push(response.notifications[i]);
            }
            setTempList(arr);
          } else {
            setTempList(response.notifications);
          }
        }
      }
      setLoading(false);
    } else {
      notification.error({
        message: t("notification_page.1"),
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (authenticated !== "unlogged") {
      history.listen(() => {
        setSelectedMenuItem(document.location.pathname.split("/")[1]);
      });
      setSelectedMenuItem(document.location.pathname.split("/")[1]);
      getAllNotification();
    }
  }, [authenticated]);

  const copyPassword = () => {
    copyToClipboard("axgQ167");
    notification.success({
      message: t("notifications.54"),
    });
  };

  const handleClick = (e) => {
    if (e.key === "popup") {
      dispatch({
        type: actions.OPEN_POPUP,
      });
    } else if (e.key === "tutorials") {
      const win = window.open(
        "https://pivony.notion.site/Pivony-Knowledge-Center-59a7a883a48349a6975bf47576edc407",
        "_blank"
      );
    } else if (e.key === "courses") {
      const win = window.open("https://learning.pivony.com/", "_blank");
      win.focus();
    } else if (e.key === "/") {
      document.location.href = document.location.origin;
    } else if (e.key === "feedback") {
      const win = window.open(
        "mailto:support@pivony.com?subject=Internal%20Support%20Ticket%20Submission",
        "_blank"
      );
      win.focus();
    } else if (e.key !== "logout") {
      history.push(e.key);
    }
  };

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          mixpanel.track("LOGOUT");
          window.localStorage.removeItem("selectedDashboard");
          document.location.href = document.location.origin + "/login";
        },
        function(error) {
          console.error("Sign Out Error", error);
        }
      );
  };

  const KnowledgeCenterPopup = () => {
    return (
      <div className="popup">
        <div className="content">
          <div className="header">
            <div className="texts">
              <div className="title">Please Check Your Password!</div>
              <div className="description">
                you will be asked to enter this password to access the knowledge
                Center
              </div>
            </div>

            <div
              className="close"
              onClick={() => {
                setshowKCenterPass(false);
              }}
            >
              <img
                src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
                alt="help"
              />{" "}
            </div>
          </div>
          <div className="inputWrapper">
            <input
              type="text"
              placeholder="Enter text"
              value={"Partners12."}
              disabled
            />
            <Button className="button" onClick={copyPassword}>
              {" "}
              <img
                src={theme === "dark" ? "/assets/copy.svg" : "/assets/copy.svg"}
                alt="help"
              />{" "}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/"
    ) {
      setShowLogin("Login");
    }
    if (window.location.pathname === "/login") {
      setShowLogin("Signup");
    }
  }, [location.pathname]);

  return (
    //<div id="header_to_menu" style={{ backgroundColor: '#262250', minHeight: 60 ,display: "flex",justifyContent: "space-between",maxHeight: 100}}>
    <div
      id="header_to_menu"
      style={{
        backgroundColor: themeColor(theme),
        minHeight: 60,
        position: "relative",
      }}
    >
      <a
        href={
          authenticated === LOGGED && user.plan
            ? user.plan == 68
              ? document.location.origin + "/sentiment_intent_analyzer"
              : document.location.origin + "/home"
            : // : document.location.origin + "/console/myDashboards"
              "//pivony.com"
        }
      >
        <div className="nav-logo">
          <div className="nav-logo-container">
            <Tooltip title="Home" placement="right">
              {theme === "brand" ? (
                <img src="/assets/pivony-logo1.png" alt="logo" />
              ) : (
                <img src="/assets/pivony-logo.png" alt="logo" />
              )}
            </Tooltip>
          </div>
        </div>
      </a>

      <></>
      {/* {(authenticated === LOGGED  && user.plan) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
      <Avatar size={60} shape ="square" src="/assets/vodafone.png"/>

      </div>)
     } */}
      {/* {(authenticated === LOGGED  && user.plan && user.logo_url) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
          <img src={user.logo_url} style={{width:"140px",height:"33px"}}/>

      </div>)
     } */}
      {/* {(authenticated === LOGGED  && user.plan) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
      <Avatar size={60} src="/assets/yw.png"/>

      </div>)
     } */}
      {showKCenterPass ? <KnowledgeCenterPopup /> : ""}

      <Popup user={user} auth={authenticated} />
      <Menu
        onClick={handleClick}
        selectedKeys={[selectedMenuItem]}
        mode="horizontal"
        theme="dark"
        className="top-navigation-bar"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: themeColor(theme),
        }}
      >
        {/* {(authenticated === LOGGED && user.plan !== 68) && (
          <Menu.Item
            style={{ color: "#fff" }}
            key="/console/myDashboards"
            icon={<FontAwesomeIcon />}
          >
            Home
          </Menu.Item>
        )}
        {(authenticated === LOGGED && user.plan === 68) ?
          <Menu.Item
            style={{ color: "#fff" }}
            key="/sentiment_intent_analyzer"
          >
            Home
          </Menu.Item>
          :
          <Menu.Item
            id="popup_selector"
            style={{ color: "#fff" }}
            key="popup"
            icon={<FontAwesomeIcon />}
          >
            Examples
          </Menu.Item>} */}
        {authenticated === LOGGED && (
          <div
            className="topbar-notification-icon"
            key="notification"
            onClick={() => {
              setShowNotification(showNotification ? false : true);
            }}
          >
            <Badge
              color={theme !== "brand" ? "#E60000" : "#4400AA"}
              count={notifsCount}
            >
              <img src="/assets/bell.svg" alt="" />
            </Badge>
          </div>
        )}
        <SubMenu
          id="help"
          className="sub-menu-item"
          popupClassName="sub-menu-item"
          title={
            <span>
              <img src="/assets/help-circle.svg" alt="help" />
            </span>
          }
        >
          {" "}
          <Menu.Item key="tutorials" className="underline-item">
            {t("top_menu.2")}{" "}
            {theme === "dark" ? (
              <img
                src="/assets/arrow-up-right.svg"
                alt=""
                width={14}
                height={14}
              />
            ) : (
              <img
                src="/assets/arrow-up-right_black.svg"
                alt=""
                width={14}
                height={14}
              />
            )}
          </Menu.Item>
          <Menu.Item key="courses" className="underline-item">
            {t("top_menu.3")}{" "}
            {theme === "dark" ? (
              <img
                src="/assets/arrow-up-right.svg"
                alt=""
                width={14}
                height={14}
              />
            ) : (
              <img
                src="/assets/arrow-up-right_black.svg"
                alt=""
                width={14}
                height={14}
              />
            )}
          </Menu.Item>
          <Menu.Item key="feedback" className="underline-item">
            {t("top_menu.6")}{" "}
            {theme === "dark" ? (
              <img
                src="/assets/arrow-up-right.svg"
                alt=""
                width={14}
                height={14}
              />
            ) : (
              <img
                src="/assets/arrow-up-right_black.svg"
                alt=""
                width={14}
                height={14}
              />
            )}
          </Menu.Item>
        </SubMenu>
        {authenticated === LOGGED && (
          <SubMenu
            id="myaccount"
            style={{ color: "#fff", width: "24px", height: "24px" }}
            className="avatar-box"
            popupClassName="avatar-submenu"
            title={
              <span className="avatar-icon">
                <span className="user-name-letter">
                  {user?.firstName
                    ? user.firstName.charAt(0).toUpperCase()
                    : user?.email
                    ? user?.email?.charAt(0).toUpperCase()
                    : "P"}
                </span>
              </span>
            }
          >
            {user.plan && (
              <Menu.Item
                onClick={() => {
                  mixpanel.track("NAVIGATE_SETTINGS");
                }}
                key="/settings"
                icon={
                  theme === "dark" ? (
                    <img src="/assets/settings.svg" alt="" />
                  ) : (
                    <img src="/assets/settings_black.svg" alt="" />
                  )
                }
                className="avatar-item"
              >
                {t("top_menu.4")}
              </Menu.Item>
            )}
            <Menu.Item
              onClick={signout}
              key="logout"
              icon={
                theme === "dark" ? (
                  <img src="/assets/log-out.svg" alt="" />
                ) : (
                  <img src="/assets/log-out-black.svg" alt="" />
                )
              }
              className="avatar-item"
            >
              {t("top_menu.5")}
            </Menu.Item>
          </SubMenu>
        )}
        {authenticated === UNLOGGED && showLogin === "Signup" && (
          <Menu.Item
            key="/signup"
            icon={<FontAwesomeIcon icon={faLongArrowAltUp} />}
            style={{ float: "right" }}
            className="avatar-item"
          >
            Signup
          </Menu.Item>
        )}
        {authenticated === UNLOGGED && showLogin === "Login" && (
          <Menu.Item
            key="/login"
            icon={<FontAwesomeIcon icon={faSignInAlt} />}
            style={{ float: "right" }}
            className="avatar-item"
          >
            Login
          </Menu.Item>
        )}
      </Menu>
      {showNotification && (
        <NotificationPage
          user={user}
          theme={theme}
          setShowNotification={setShowNotification}
          filterUnread={filterUnread}
          setFilterUnread={setFilterUnread}
          notificationList={notificationList}
          setNotificationList={setNotificationList}
          showNoUnreadAvailable={showNoUnreadAvailable}
          setShowNoUnreadAvailable={setShowNoUnreadAvailable}
          tempList={tempList}
          setTempList={setTempList}
          loading={loading}
          setLoading={setLoading}
          showEmptyState={showEmptyState}
          setShowEmptyState={setShowEmptyState}
          showLast30Days={showLast30Days}
          setShowLast30Days={setShowLast30Days}
          getAllNotification={getAllNotification}
        />
      )}
      {/* render only if url is not login or user is authenticated */}
      {authenticated === LOGGED &&
        window.location.href.split("/").includes("login") === false && (
          <div style={{ position: "relative" }}>
            <SystemNotification />
          </div>
        )}
    </div>
  );
};

export default TopMenu;
