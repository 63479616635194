import CustomChart from "components/Charts";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Dashboard/DashboardWrapper/style.module.scss";

const KPIBubbleChart = ({ data }) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  let x_miny = 999;
  let x_maxy = -999;
  let y_miny = 999;
  let y_maxy = -999;

  const graphData = () => {
    if (data && data.length > 0) {
      data.map((item) => {
        //Calculate max and min value for the graph x-axis
        x_maxy = Math.max(x_maxy, Math.abs(item?.x));
        x_miny = Math.min(x_miny, Math.abs(item?.x));

        //Calculate max and min value for the graph y-axis
        y_maxy = Math.max(y_maxy, Math.abs(item?.y));
        y_miny = Math.min(y_miny, Math.abs(item?.y));
      });

      return data.map((item) => ({
        x: Math.abs(item?.x ?? 0),
        y: Math.abs(item?.y ?? 0),
        label: item?.Label ?? "No Label",
        r: item?.NumDocs ?? 10,
      }));
    }
  };

  // const graphData = data[0][0].map((item) => {
  //   return {
  //     x_axis: item?.x,
  //     y_axis: item?.y,
  //     label: item?.Label,
  //     r: item?.r,
  //   };
  // });
  return Object.keys(data)?.length !== 0 ? (
    <CustomChart
      width="350px"
      height="280px"
      type="bubble"
      data={{
        datasets: [
          {
            label: "Topics",
            data: graphData(),
            backgroundColor: "#2E7316",
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { display: false, min: x_miny - 2, max: x_maxy + 2 },
          y: { display: false, min: y_miny - 2, max: y_maxy + 2 },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
            external: (context) => {
              // Tooltip Element
              var tooltipEl = document.getElementById("chartjs-tooltip");
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<div></div>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                if (tooltipEl) {
                  tooltipEl.remove();
                }
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                const bubbleData = data;
                let innerHtml = "<div class=" + styles.driversTooltip + ">";
                let dataset = {};
                let dataItem = {};
                tooltipModel.dataPoints.forEach((dataPoint) => {
                  const datasetIndex = dataPoint.datasetIndex;
                  dataset = bubbleData[datasetIndex];
                });
                const topWords = dataset.Top20Words?.slice(0, 10);
                // For emergent topic

                innerHtml += `
                    <span class=${
                      styles.topicLabel
                    }><b>${"Emergent Topic ⚠️"}</b></span>
                  `;

                innerHtml += `
                  <div class=${styles.topicLabelInTooltip}>
                    <span class=${styles.fillColor} style="background-color: ${
                  dataset.BackgroundColor
                }"></span>
                    <span class=${styles.topicLabel}>${dataset.Label}</span>
                  </div>
                <p class=${
                  styles.tooltipValues
                }><b>Top Words:</b> ${topWords.join(", ")}</p>
              `;

                innerHtml += "</div>";
                const tooltipRoot = tooltipEl.querySelector("div");
                tooltipRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = "absolute";
              let left =
                position.left + window.pageXOffset + tooltipModel.caretX;
              if (left + tooltipEl.offsetWidth > window.innerWidth) {
                left =
                  position.left +
                  window.pageXOffset / 2 +
                  tooltipModel.caretX -
                  tooltipEl.offsetWidth;
              }
              tooltipEl.style.left = left + "px";
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.padding =
                tooltipModel.padding + "px " + tooltipModel.padding + "px";
              tooltipEl.style.pointerEvents = "none";
            },
          },
          datalabels: {
            color: theme == "dark" ? "white" : "black", // Label color
            font: {
              size: 20, // Font size of the labels
            },
          },
        },
      }}
    />
  ) : (
    <div
      style={{
        fontSize: "20px",
        color: "white",
        width: "200px",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      No Data Available
    </div>
  );
};

export default KPIBubbleChart;
