import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";
import CustomChart from "components/Charts";
import { useTranslation } from "react-i18next";
const BarChartWrapper = ({
  data,
  selectedTopics,
  setSelectedTopics,
  metric,
  stacked,
  disableSelection,
  onlyOneMetric,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const chartRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);

  // Extract labels, ratings, and children for chart data
  const removeParentFromChildren = (parent) => {
    if (parent && parent !== "") {
      const splitLabel = parent.split(" > ");
      if (splitLabel?.length > 1) {
        splitLabel.shift(); // Remove the first element
        return splitLabel.join(" > ");
      } else {
        return splitLabel;
      }
    } else {
      return parent;
    }
  };

  const labels = data.map((item) => item.label);
  const ids = data.map((item) => item.id);
  const values = data.map((item) => item[metric]);
  const children = data.map((item) => item?.child);
  const noOfBars = labels?.length;
  values.forEach((val) => {
    let sum = 0;
    Object.keys(val).forEach((key) => {
      sum += val[key]?.percentage;
    });
  });
  const sentLabelIdMap = {
    "0.0": "negative",
    "0.5": "neutral",
    "1.0": "positive",
    "-1": "unspecified",
  };
  // Configure chart data for stacked and non-stacked cases
  const chartData = {
    labels: labels,
    datasets: stacked
      ? [
          {
            label: t("feedback_dropdown_values.4"),
            data: data.map((item) => item[metric]["0.0"]?.percentage),
            backgroundColor: "rgba(220, 37, 37, 1)",
            barThickness:
              noOfBars > 30
                ? "flex"
                : (isMobileView && noOfBars > 12) ||
                  (window.innerWidth > 1250 && noOfBars > 8 && !onlyOneMetric)
                ? 16
                : onlyOneMetric && noOfBars < 12
                ? 28
                : 24,
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            // lineTension: 1.0,
            borderRadius: 5,
          },
          {
            label: t("feedback_dropdown_values.3"),
            data: data.map((item) => item[metric]["0.5"]?.percentage),
            backgroundColor: "rgb(255, 255, 0, 0.8)",
            barThickness:
              noOfBars > 30
                ? "flex"
                : (isMobileView && noOfBars > 12) ||
                  (window.innerWidth > 1250 && noOfBars > 8 && !onlyOneMetric)
                ? 16
                : onlyOneMetric && noOfBars < 12
                ? 28
                : 24,
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            // lineTension: 1.0,
            borderRadius: 5,
          },
          {
            label: t("feedback_dropdown_values.2"),
            data: data.map((item) => item[metric]["1.0"]?.percentage),
            backgroundColor: "rgba(16, 200, 0, 0.8)",
            barThickness:
              noOfBars > 30
                ? "flex"
                : (isMobileView && noOfBars > 12) ||
                  (window.innerWidth > 1250 && noOfBars > 8 && !onlyOneMetric)
                ? 16
                : onlyOneMetric && noOfBars < 12
                ? 28
                : 24,
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            // lineTension: 1.0,
            borderRadius: 5,
          },
          {
            label: t("feedback_dropdown_values.1"),
            data: data.map((item) => item[metric]["-1"]?.percentage),
            backgroundColor: "rgba(224, 131, 17, 0.664)",
            barThickness:
              noOfBars > 30
                ? "flex"
                : (isMobileView && noOfBars > 12) ||
                  (window.innerWidth > 1250 && noOfBars > 8 && !onlyOneMetric)
                ? 16
                : onlyOneMetric && noOfBars < 12
                ? 28
                : 24,
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            // lineTension: 1.0,
            borderRadius: 5,
          },
        ]
      : [
          {
            label: metric === "rating" ? t("label.20") : t("label.18"),
            data: values?.map((val) => val?.value),
            datalabels: {
              display: false,
            },
            backgroundColor: "#4400AA",
            barThickness:
              noOfBars > 30
                ? "flex"
                : (isMobileView && noOfBars > 12) ||
                  (window.innerWidth > 1250 && noOfBars > 8 && !onlyOneMetric)
                ? 16
                : onlyOneMetric && noOfBars < 12
                ? 28
                : 24,
            categoryPercentage: 1.0,
            barPercentage: 0.7,
            borderRadius: 5,
          },
        ],
  };
  // Configure chart options
  const chartOptions = {
    scales: {
      y: {
        stacked: stacked,
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: theme === "dark" ? "white" : "black",
          display: true,
          drawOnChartArea: false,
          drawTicks: false,
          offset: true,
        },
        title: {
          display: metric === "sentiment" ? true : false,
          text: `${t("label.18")} (%)`,
          color: theme === "dark" ? "white" : "black",
        },
        color: theme === "dark" ? "white" : "black",
        scaleLabel: metric === "sentiment" && `${t("label.18")} (%)`,
        axis: "y",
        display: true,
        ticks: {
          beginAtZero: true,
          stepSize: metric === "sentiment" ? 20 : 1,
          font: {
            color: theme === "dark" ? "white" : "black",
          },
          color: theme === "dark" ? "white" : "black",
          maxTicksLimit: 6,
        },
      },
      x: {
        stacked: stacked,
        border: {
          display: false,
        },
        ticks: {
          display: true,
          autoSkip: false,
          padding: 10,
          maxRotation: 60,
          minRotation: 60,
          color: theme === "dark" ? "white" : "black",
          font: {
            color: theme === "dark" ? "white" : "black",
            size: 10,
          },
          displayColors: false,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          callback: (val) => {
            const label = labels[val];
            if (removeParentFromChildren(label) !== "") {
              return removeParentFromChildren(label).length > 12
                ? removeParentFromChildren(label).substr(0, 12) + "..."
                : removeParentFromChildren(label);
            } else {
              return label.length > 12 ? label.substr(0, 12) + "..." : label;
            }
          },
        },
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: theme === "dark" ? "white" : "black",
          display: true,
          drawOnChartArea: false,
          drawTicks: false,
          offset: false,
        },
        color: theme === "dark" ? "white" : "black",
      },
    },
    display: false,
    responsive: true,
    maintainAspectRatio: false,
    bar: {
      borderRadius: 15,
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function(context) {
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }
          const tooltipModel = context.tooltip;
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            if (tooltipEl) {
              tooltipEl.remove();
            }
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            let innerHtml = "<div class=" + styles.driversTooltip + ">";
            const index = tooltipModel.dataPoints[0].dataIndex;
            const hoveredPoint = tooltipModel.dataPoints[0].datasetIndex;
            let stackVal = "";
            stackVal = "1.0";
            if (hoveredPoint === 2) {
            } else if (hoveredPoint === 1) {
              stackVal = "0.5";
            } else if (hoveredPoint === 0) {
              stackVal = "0.0";
            } else if (hoveredPoint === 3) {
              stackVal = "-1";
            }
            if (stacked) {
              innerHtml += `
              <span class=${styles.stackInfo}>
                <span class="${styles.stackColor} ${
                styles[sentLabelIdMap[stackVal]]
              }" ></span> 
                <span class=${styles.stackLabel}>${
                sentLabelIdMap[stackVal]
              }</span>
              </span>
            `;
            }
            innerHtml += `<span class=${
              styles.parentLabel
            }>${removeParentFromChildren(labels[index])}: ${
              stacked
                ? values[index][stackVal]?.percentage
                  ? `${values[index][stackVal].percentage.toFixed(1)}% (${
                      values[index][stackVal].value
                    })`
                  : 0
                : `${values[index]["value"]} (${values[index]["reviewsCount"]})`
            }</span>`;
            if (children[index]?.length > 0) {
              if (metric === "rating") {
                innerHtml += `
                  <span class=${styles.childLabel}>
                    ${t("topic_library.35")} ${t(
                  "label.20"
                )} : Rating and Counts of Sub-topics
                  </span>`;
              } else {
                innerHtml += `
                <span class=${styles.childLabel}>
                  ${t("topic_library.35")} ${t(
                  "label.18"
                )} : Sentiment and Counts of Sub-topics
                </span>`;
              }
              innerHtml += `<div class=${styles.childContainer}>`;
              function addChildren(child) {
                innerHtml += `<span>${removeParentFromChildren(child.label)}: ${
                  stacked
                    ? child[metric][stackVal]?.percentage
                      ? `${parseFloat(
                          child[metric][stackVal]?.percentage.toFixed(1)
                        )}% (${child[metric][stackVal]?.value})`
                      : 0
                    : `${child[metric]["value"]} (${child[metric]["reviewsCount"]})`
                }</span>`;
                if (child.child && child.child.length > 0) {
                  innerHtml += `<div class=${styles.childContainer}>`;
                  child.child.forEach(addChildren);
                  innerHtml += "</div>";
                }
              }
              children[index].forEach(addChildren);
              innerHtml += "</div>";
            }
            innerHtml += "</div>";
            const tooltipRoot = tooltipEl.querySelector("div");
            tooltipRoot.innerHTML = innerHtml;
          }
          // this will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipEl.offsetWidth > window.innerWidth) {
            left =
              position.left +
              window.pageXOffset / 2 +
              tooltipModel.caretX -
              tooltipEl.offsetWidth;
          }
          tooltipEl.style.left = left + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
    onClick: (event) => {
      if (disableSelection) {
        return;
      }
      if (chartRef) {
        const activeElement = chartRef.current.getElementsAtEventForMode(
          event,
          "nearest",
          { intersect: true },
          true
        );
        if (activeElement?.length > 0) {
          const clickedIndex = activeElement[0].index;
          if (children[clickedIndex]?.length > 0) {
            if (Object.keys(selectedTopics).length === 0) {
              setSelectedTopics({
                [ids[clickedIndex]]: {
                  level: 1,
                  id: ids[clickedIndex],
                  label: labels[clickedIndex],
                },
              });
            } else {
              setSelectedTopics((prev) => {
                const prevLevels = Object.values(prev).map(
                  (item) => item.level
                );
                const maxLevel = Math.max(...prevLevels);
                const newLevel =
                  maxLevel + (prevLevels.includes(maxLevel) ? 1 : 0);
                return {
                  ...prev,
                  [ids[clickedIndex]]: {
                    level: newLevel,
                    id: ids[clickedIndex],
                    label: labels[clickedIndex],
                  },
                };
              });
            }
          } else {
            return;
          }
        }
      }
    },
  };
  const [barDependency, setBarDependency] = useState(1);
  useEffect(() => {
    setBarDependency(barDependency === 1 ? 2 : 1);
  }, [data]);
  return (
    <div className={styles.barChartWrapper}>
      {/* <div style={noOfBars > 20 ? { width: `${noOfBars * 30}px` } : {}}> */}
      <div>
        <CustomChart
          type="bar"
          data={chartData}
          height={250}
          options={{
            ...chartOptions,
            plugins: {
              ...chartOptions.plugins,
              datalabels: {
                display: false,
              },
            },
          }}
          ref={chartRef}
          theme={theme}
          dependency={barDependency}
        />
      </div>
    </div>
  );
};

export default BarChartWrapper;
