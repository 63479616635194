import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import InsightsCard from "components/Insights/InsightsCard";
import { apiRequest } from "util/services";
import { useSelector, useDispatch } from "react-redux";
import { notification, Spin, Button, Input } from "antd";
import CustomInput from "components/General/CustomInput";
import { UNLOGGED } from "util/constants";
import * as actions from "redux/Dashboard/actions.js";
import { useHistory } from "react-router-dom";
import BoardError from "components/Dashboard/BoardError";
import NestedTabs from "components/NestedTabs";
import DashboardHeader from "components/Dashboard/Header";
import { Modal } from "components/General/ModalFormComponents";
import { useTranslation } from "react-i18next";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const Insights = ({
  authenticated,
  user,
  currentDashboard,
  shared,
  currentDashboardHash,
  match,
  theme,
}) => {
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const history = useHistory();
  if (!currentDashboard && !DashboardReducer.dashboard && !match.params.id) {
    history.push("/home");
    // history.push("/console/myDashboards");
  }
  let initialState = {
    currentDashboard: shared
      ? currentDashboard
      : match.params.id || DashboardReducer.dashboard?.ID,
  };
  const [localState, setState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [insightTitleTxt, setinsightTitleTxt] = useState("");
  const [insightTxt, setInsightTxt] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [createInsightLoading, setCreateInsightLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    if (authenticated === UNLOGGED && !shared) {
      document.location.href = document.location.origin;
      return;
    }
    if (!localState.currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/home");
      // history.push("/console/myDashboards");
      return;
    }
    checkDashboardStatus(localState.currentDashboard);
  }, []);

  function resizeGridItem(item) {
    const grid = document.querySelector("[class^=style_insightsContainer]");
    const rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    );
    const rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    );
    const rowSpan = Math.ceil(
      (item.querySelector(".ant-card").getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap)
    );
    item.style.gridRowEnd = "span " + rowSpan;
  }

  function resizeAllGridItems() {
    const allItems = document.querySelectorAll("." + styles.container);
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x]);
    }
  }

  useEffect(() => {
    resizeAllGridItems();
    window.addEventListener("resize", resizeAllGridItems);
  }, [insights]);

  const checkDashboardStatus = async (id) => {
    const result = await apiRequest(
      "dashboards/v2/get",
      "POST",
      {
        dashboard_id: parseInt(id, 10),
      },
      true
    );
    if (result.error) {
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    } else if (result.status?.ID === -1 || result.status?.ID === 5) {
      notification.info({
        message: t("notifications.44"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/home`);
      // history.push("/console/myDashboards");
    } else if (
      result.status.ID !== 4 &&
      result.status.ID !== 6 &&
      !result?.dashboard?.IsDynamic
    ) {
      notification.info({
        message: t("notifications.45"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/journey/${initialState.currentDashboard}`);
    } else {
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          ...result,
          loading: true,
        },
      });
      getInsights(result.dashboard.ID);
    }
  };

  const getInsights = async (dashboard_id) => {
    setLoading(true);
    const result = await apiRequest(
      "dashboards/insights/get",
      "POST",
      { dashboard_id },
      true
    );
    if (!result.error) {
      setInsights(result);
    } else {
      notification.error({ message: result.error });
    }
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    setLoading(false);
  };

  if (DashboardReducer.failureMsg)
    return <BoardError failureMsg={DashboardReducer.failureMsg} />;

  const handleCreateInsight = async () => {
    if (!insightTxt) {
      notification.error({ message: t("notifications.46") });
      return;
    }

    setCreateInsightLoading(true);
    const payload = {
      dashboard_id: localState.currentDashboard,
      insight_title: insightTitleTxt,
      insight: insightTxt,
      reporter: userReducer.IsExpert ? 2 : 1,
    };
    const result = await apiRequest(
      "dashboards/insights/create",
      "POST",
      payload,
      true
    );
    if (result.cancel || result.error || result.validation_error) {
      notification.error({
        message: result.error ? result.error : "Failed to post Insight",
      });
    } else {
      notification.success({ message: t("notifications.143") });
    }
    setCreateInsightLoading(false);
    setCreateModal(false);
    getInsights(localState.currentDashboard);
    setInsightTxt("");
    setinsightTitleTxt("");
  };

  return (
    <div className={styles.mainWindow}>
      <DashboardHeader
        shared={shared}
        currentDashboardHash={currentDashboardHash}
        user={user}
        topicsType="insight"
      />
      {!shared && <NestedTabs />}
      <div className={styles.createInsight}>
        {!shared && (
          <Button
            icon={<i className="fa fa-plus" />}
            onClick={() => setCreateModal(true)}
            disabled={shared}
          >
            {t("insights_page.1")}
          </Button>
        )}
      </div>
      <Spin spinning={loading}>
        {insights?.length === 0 && !loading ? (
          <div className={styles.noInsights}>
            <p className={styles.heading}>
              {t("empty_states.1")}{" "}
              <img
                src={
                  theme === "dark"
                    ? "/assets/emptyStates/empty_insight.svg"
                    : "/assets/emptyStates/empty_insight_black.svg"
                }
                alt=""
              />
            </p>
            <div className={styles.secondRow}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/error_outline.svg"
                    : "/assets/error_outline_black.svg"
                }
                alt=""
              />
              {t("empty_states.2")}
            </div>
            <p className={styles.secondHeading}>
              {t("empty_states.3")}{" "}
              <img src={"/assets/double_down_arrow.svg"} alt="" />
            </p>
            <div className={styles.imgBlock}>
              <div>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/emptyStates/insight1.png"
                      : theme === "light"
                      ? "/assets/emptyStates/insight1_light.png"
                      : "/assets/emptyStates/insight1_brand.png"
                  }
                  alt=""
                />
              </div>
              <div>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/emptyStates/insight2.png"
                      : theme === "light"
                      ? "/assets/emptyStates/insight2_light.png"
                      : "/assets/emptyStates/insight2_brand.png"
                  }
                  alt=""
                />
              </div>
              <div>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/emptyStates/insight3.png"
                      : theme === "light"
                      ? "/assets/emptyStates/insight3_light.png"
                      : "/assets/emptyStates/insight3_brand.png"
                  }
                  alt=""
                />
              </div>
            </div>
            <p
              className={styles.link}
              onClick={() =>
                history.push(
                  `/console/DashboardData/${localState.currentDashboard}`
                )
              }
            >
              {t("empty_states.4")}
            </p>
          </div>
        ) : (
          <div className={styles.insightsContainer}>
            {insights.map((insight, index) => {
              return (
                <InsightsCard
                  insight={insight}
                  insights={insights}
                  setInsights={setInsights}
                  shared={shared}
                  currentDashboardHash={currentDashboardHash}
                  className={styles.container}
                  id={initialState.currentDashboard}
                  user={user}
                  theme={theme}
                />
              );
            })}
          </div>
        )}
      </Spin>

      <Modal
        showModal={createModal}
        onClose={() => {
          setCreateModal(false);
          setInsightTxt("");
          setinsightTitleTxt("");
        }}
        onSave={handleCreateInsight}
        showSaveButton={true}
        titleAsset=""
        title={
          <span className={styles.insightTitle}>
            {t("selected_dashboard_page.65")}{" "}
            <img
              className={styles.message_img}
              src={
                theme === "dark"
                  ? "/assets/info1.svg"
                  : "/assets/info1_black.svg"
              }
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              onClick={() => {
                const win = window.open(
                  "https://pivony.notion.site/Insight-Creation-160faa1a90cc4191baf11fb62c88d808",
                  "_blank"
                );
                win.focus();
              }}
            />
          </span>
        }
        widthofmodal="420px"
        loadingSaveClick={createInsightLoading}
      >
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "2px",
              marginBottom: "7px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("placeholder.14")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setinsightTitleTxt(e.target.value)}
            value={insightTitleTxt}
          />
        </div>
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "130px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("placeholder.15")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setInsightTxt(e.target.value)}
            value={insightTxt}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Insights;
