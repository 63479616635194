import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import KPIBubbleChart from "../../components/WelcomePage/KPIBubbleChart";
import { useHistory, useLocation } from "react-router-dom";
import { notification } from "antd";
import { UNLOGGED } from "util/constants";
import { apiRequest } from "util/services";
import { useDispatch, useSelector } from "react-redux";
import { themeColor } from "util/common";
import { Layout, Row, Col, Card, Button, Tooltip } from "antd";
import KPILineChart from "../../components/WelcomePage/KPILineChart";
import moment from "moment";

import {
  FileDoneOutlined,
  DownloadOutlined,
  ThunderboltOutlined,
  DashboardOutlined,
  TeamOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TikTokOutlined,
  OpenAIOutlined,
  PlayCircleOutlined,
  BulbOutlined,
  PaperClipOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const { Content } = Layout;

const WelcomePage = ({ match, shared, authenticated, user }) => {
  const history = useHistory();
  const [teamName, setTeamName] = useState([]);
  const [openRecomm, setOpenRecomm] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [emergentTopic, setEmergentTopic] = useState([]);
  const [countDashboard, setCountDashboard] = useState(0);
  const [alertsCount, setAlertsCount] = useState([]);
  const theme = useSelector((state) => state.DashboardReducer.theme);

  const handleClick = (e) => {
    if (e.currentTarget.dataset.key == "learning_center") {
      const win = window.open("https://learning.pivony.com/", "_blank");
      win.focus();
    }
    if (e.currentTarget.dataset.key == "knowledge_center") {
      const win = window.open(
        "https://pivony.notion.site/Pivony-Knowledge-Center-59a7a883a48349a6975bf47576edc407",
        "_blank"
      );
      win.focus();
    }
    if (e.currentTarget.dataset.key == "blogs") {
      const win = window.open("https://www.pivony.com/blogs", "_blank");
      win.focus();
    }
    if (e.currentTarget.dataset.key == "contact") {
      const win = window.open("mailto:support@pivony.com", "_blank");
      win.focus();
    }
  };
  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
    }
    setLoading(true);
    const getUserTeam = async () => {
      try {
        const result = await apiRequest("users/team", "POST");
        if (result?.success) {
          setTeamName(result?.success);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserTeam();
    //get subscription details

    const getSubscriptionData = async () => {
      try {
        const result = await apiRequest("welcome/subscription-details", "GET");
        if (result) {
          setSubscriptionData(result);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Subscription Data Not Available",
        });
      }
    };
    getSubscriptionData();
    const getHomeData = async () => {
      try {
        const result = await apiRequest("welcome/most-recent-report", "POST", {
          sort: "newest",
        });
        if (result) {
          setReportData(result);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Report Data Not Available",
        });
      }
    };
    getHomeData();
    const getKPIData = async () => {
      try {
        const result = await apiRequest("welcome/kpi-data", "GET");
        if (result) {
          setKpiData(result);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "KPI Data Not Available",
        });
      }
    };
    getKPIData();
    const getEmergentTopic = async () => {
      try {
        const result = await apiRequest("welcome/emergent-topic", "GET");
        if (result) {
          setEmergentTopic(result?.topics[0]);
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Top Topic Not Available",
        });
      }
    };
    getEmergentTopic();
    setLoading(false);
  }, []);
  const kpiDataDummy = {
    topIncreasingKpi: { name: "Sales Growth", value: "+20%" },
    topDecreasingKpi: { name: "Customer Churn", value: "-5%" },
    topEmergentTopics: ["Topic 1", "Topic 2", "Topic 3"],
  };
  const handleReportDownload = (link) => {
    if (link) {
      window.open(link, "_blank");
    } else {
      notification.error({
        message: "Error",
      });
    }
  };
  const ReportItem = ({ reportName, csvLink }) => (
    <div className={styles.tableRow}>
      <p className={styles.nameRow}>{reportName}</p>
      <p className={styles.moreRow}>
        <div
          onClick={() => handleReportDownload(csvLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadOutlined />
        </div>
      </p>
    </div>
  );
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className={styles.mainContent}>
        <div className={styles.introSection}>
          <div className={styles.top}>
            <div className={styles.title}>
              <div className={styles.text}>
                <h5>Get Started 🚀</h5>
              </div>
            </div>
            {teamName ? (
              <div className={styles.teamBtn}>
                {" "}
                <TeamOutlined /> {teamName}{" "}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={styles.introCTA}>
            <div>
              Discover Actionable{" "}
              <strong className={styles.emphasizedTextStyle}>Insights</strong>{" "}
              in Every Customer Conversation
            </div>
            <div className={styles.ctaGroupButton}>
              <Row gutter={16}>
                <Button
                  className={styles.ctaButton}
                  icon={<FileDoneOutlined />}
                  onClick={() => history.push("/console/report")}
                >
                  Customize your Report
                </Button>
                <Button
                  className={styles.ctaButton}
                  icon={<DashboardOutlined />}
                  onClick={() => history.push("/console/global_executive")}
                >
                  Monitor brand health
                </Button>
                <Button
                  className={styles.ctaButton}
                  icon={<ThunderboltOutlined />}
                  onClick={() => history.push("/console/industryTopics")}
                >
                  Competitive Benchmarking
                </Button>
              </Row>
            </div>
          </div>
        </div>
        {(Object.keys(alertsCount) != 0 && alertsCount?.count == 0) ||
        countDashboard == 0 ? (
          <>
            <div className={styles.cardsTitle}>
              {openRecomm ? (
                <>We've got some recommendations you might like! </>
              ) : (
                ""
              )}
              <div
                className={styles.hideOption}
                onClick={() => {
                  setOpenRecomm(!openRecomm);
                }}
              >
                {openRecomm ? (
                  "Hide Recommendation"
                ) : (
                  <div style={{ marginBottom: "20px" }}>
                    Show Recommendation
                  </div>
                )}
              </div>
            </div>
            {openRecomm ? (
              <Row gutter={[16, 24]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    hoverable
                    title={<span className={styles.titleContent}>Alerts</span>}
                    bordered={false}
                    className={styles.welcomeCard}
                  >
                    <div className={styles.icon3}>
                      <AlertOutlined className={styles.iconImg} />
                    </div>
                    <ul>
                      Alert is a powerful tool that helps businesses track key
                      performance indicators (KPIs) in real time.
                    </ul>
                    <ul>
                      <Button
                        onClick={() => {
                          history.push("console/alerts");
                        }}
                        className={styles.ctaButton}
                      >
                        Set Alert
                      </Button>
                    </ul>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  {/* Adjusted column span to 12 */}
                  <Card
                    hoverable
                    title={
                      <span className={styles.titleContent}>
                        Public Data Integration{" "}
                      </span>
                    }
                    bordered={false}
                    className={styles.welcomeCard}
                  >
                    <div className={styles.icon3}>
                      <FacebookOutlined className={styles.iconImg} />
                      <InstagramOutlined className={styles.iconImg} />
                      <LinkedinOutlined className={styles.iconImg} />
                      <TikTokOutlined className={styles.iconImg} />
                    </div>
                    <ul>
                      Gain Deeper Customer Insights by Integrating Social Media
                      Data from Facebook and Instagram.
                    </ul>
                    <ul>
                      <Button
                        onClick={() => {
                          history.push("settings/integrations");
                        }}
                        className={styles.ctaButton}
                      >
                        Integrate
                      </Button>
                    </ul>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : null}
        <div className={styles.cardsTitleWithInfo}>
          Key Insights
          <Tooltip
            placement="bottom"
            title="Last Week's Insights from Your Organization's KPIs and Dashboards"
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {/* KPI Cards Section */}
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              hoverable
              title={
                <span className={styles.titleContent}>Top Increasing KPI</span>
              }
              bordered={false}
              className={styles.welcomeCard}
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
            >
              <div className={styles.kpiMainContainer}>
                {Object.keys(kpiData)?.length !== 0 &&
                Object.keys(kpiData?.top_increasing !== 0) ? (
                  <div>
                    {kpiData?.top_increasing?.percentage_change ==
                    "No change" ? (
                      <div
                        style={{
                          fontSize: "20px",
                          color: theme === "dark" ? "white" : "black",
                        }}
                      >
                        No Change
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "#0A8F0E",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "14px",
                          }}
                        >
                          {kpiData?.top_increasing?.percentage_change}

                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      Vs
                      <span style={{ marginRight: "4px" }}> Last Week</span>
                    </div>
                    <div
                      className={styles.kpiChartContainer}
                    >
                      <KPILineChart data={kpiData?.top_increasing} />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "20px",
                      color: theme === "dark" ? "white" : "black",
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No KPI
                  </div>
                )}
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              hoverable
              title={
                <span className={styles.titleContent}>Top Decreasing KPI</span>
              }
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              bordered={false}
              className={styles.welcomeCard}
            >
              {Object.keys(kpiData)?.length !== 0 &&
              Object.keys(kpiData?.top_decreasing !== 0) ? (
                <div>
                  {kpiData?.top_decreasing?.percentage_change == "No change" ? (
                    <div
                      style={{
                        fontSize: "20px",
                        color: theme === "dark" ? "white" : "black",
                      }}
                    >
                      No Change{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "#C94445",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "14px",
                        }}
                      >
                        - {kpiData?.top_decreasing?.percentage_change}%
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Vs
                    <span style={{ marginRight: "4px" }}> Last Week</span>
                  </div>
                  <div
                    className={styles.kpiChartContainer}
                  >
                    <KPILineChart data={kpiData?.top_decreasing} />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "20px",
                    color: theme === "dark" ? "white" : "black",
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No KPI
                </div>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Card
              hoverable
              title={
                <span className={styles.titleContent}>Emergent Topics</span>
              }
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin +
                    "/console/global_executive"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
              bordered={false}
              className={styles.welcomeCard}
            >
              <div>
                {emergentTopic ? (
                  <KPIBubbleChart data={emergentTopic} />
                ) : (
                  <div
                    style={{
                      fontSize: "20px",
                      color: theme === "dark" ? "white" : "black",
                      width: "200px",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Data Available
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        {/* Subscription Information */}
        <div className={styles.cardsTitleWithInfo}>
          Your Subscription and Recent Activity
          <Tooltip
            placement="bottom"
            title="Here you can find details about your subscription plan and reports generated in the last week."
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              hoverable
              title={
                <span className={styles.titleContent}>
                  Subscription Details
                </span>
              }
              className={styles.welcomeCard}
              bordered={false}
            >
              <div>
                <p>
                  <strong>Plan:</strong> {subscriptionData?.PackageName}
                </p>
                <p>
                  <strong>Remaining Dashboards:</strong>{" "}
                  {subscriptionData?.RemainingTopicBoards}
                </p>
                <p>
                  <strong>Next Refresh:</strong>{" "}
                  {moment(subscriptionData?.EndDate).format("ll")}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            {/* Report Section */}

            <Card
              title={
                <span className={styles.titleContent}>Recent Reports</span>
              }
              hoverable
              className={styles.welcomeCard}
              bordered={false}
              extra={
                <a
                  className={styles.navigationLink}
                  href={`${window.location.origin + "/console/report"}`}
                >
                  <ArrowRightOutlined />
                </a>
              }
            >
              <div className={styles.tableContainer}>
                {Object.keys(reportData)?.length !== 0 ? (
                  <div>
                    {reportData.map((item) => (
                      <div>
                        <ReportItem
                          reportName={item.Name}
                          csvLink={item.ReportLink}
                        />
                        <div className={styles.rowSep}></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No Report Data Available</div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "18px",
                  }}
                ></div>
              </div>
            </Card>
          </Col>
        </Row>

        <div className={styles.cardsTitleWithInfo}>
          {" "}
          Help and Support
          <Tooltip
            placement="bottom"
            title="Access in-depth guides, tutorials, and product manuals for a better understanding of our features and tools."
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        <div className={styles.cardsSubTitle}>
          Explore a comprehensive knowledge base filled with articles, guides,
          and best practices to help you leverage the platform's full potential.
        </div>

        <Row gutter={[16, 24]}>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              hoverable
              data-key="learning_center"
              onClick={handleClick}
              bordered={false}
              cover={
                <div className={styles.coverImg}>
                  <PlayCircleOutlined
                    twoToneColor="red"
                    style={{ fontSize: "50px", color: "red" }}
                  />
                </div>
              }
            >
              <span className={styles.hideOption}>
                {" "}
                Explore our Learning Center for tutorials{" "}
              </span>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              cover={
                <div className={styles.coverImg}>
                  <BulbOutlined
                    style={{ fontSize: "50px", color: "#F4C430" }}
                  />
                </div>
              }
              data-key="knowledge_center"
              onClick={handleClick}
              hoverable
              bordered={false}
            >
              <span className={styles.hideOption}>
                Explore our knowledge center
              </span>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              cover={
                <div className={styles.coverImg}>
                  <PaperClipOutlined
                    style={{ fontSize: "50px", color: "blue" }}
                  />
                </div>
              }
              data-key="blogs"
              onClick={handleClick}
              hoverable
              bordered={false}
            >
              <span className={styles.hideOption}>
                Explore our latest blogs and articles{" "}
              </span>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Card
              className={styles.supportCard}
              cover={
                <div className={styles.coverImg}>
                  <OpenAIOutlined
                    spin
                    style={{ fontSize: "50px", color: "black" }}
                  />
                </div>
              }
              bordered={false}
            >
              CX GPT is Coming Soon – Get Ready!
            </Card>
          </Col>
        </Row>
        <div className={styles.cardsSubTitle}>
          Need more help? Our dedicated support team is here for you! Reach out
          via email or chat, we're happy to assist with any questions or
          technical issues.
        </div>
        <div className={styles.ctaGroupButton}>
          <Button
            className={styles.ctaButton}
            data-key="contact"
            onClick={handleClick}
          >
            Contact Us
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default WelcomePage;
