import React from "react";
import CustomChart from "components/Charts";
import styles from "../../pages/GlobalExecutiveDashboard/style.module.scss";
import { display } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

const KPILineChart = ({ data }) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "point",
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create tooltip element if it doesn't exist
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.transition = "all 0.2s ease";
            document.body.appendChild(tooltipEl);
          }

          // Get the tooltip model
          const tooltipModel = context.tooltip;

          // Hide the tooltip if there's no opacity
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set tooltip text
          if (tooltipModel.body) {
            const dataPoints = tooltipModel.dataPoints || [];
            let innerHtml =
              "<div style='background: #333; color: #fff; padding: 8px; border-radius: 4px;'>";
            dataPoints.forEach((dataPoint) => {
              const { label, raw } = dataPoint;
              innerHtml += `<div>${label}: ${raw}</div>`;
            });
            innerHtml += "</div>";
            tooltipEl.innerHTML = innerHtml;
          }

          // Position the tooltip
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${position.left +
            window.pageXOffset +
            tooltipModel.caretX}px`;
          tooltipEl.style.top = `${position.top +
            window.pageYOffset +
            tooltipModel.caretY}px`;
          tooltipEl.style.opacity = 1;
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        //   anchor: "start",
        //   align: "top",
        //   color: theme === "dark" ? "rgba(232, 10, 10, 0.5)" : "#000000",
        // display: showDataLabel || window.showDataLabel === true ? true : false,
        display: false,
      },
    },
    scales: {
      x: {
        color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "#000000",
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "#000000",
          font: {
            size: 12,
          },
          // padding: 20,
        },
      },

      y: {
        display: false,
        afterDataLimits: (axis) => {
          const max = axis.max;
          let addVal = 0;
          if (max < 5) {
            addVal = 2;
          } else if (max < 10) {
            addVal = 5;
          } else if (max < 100) {
            addVal = 10;
          } else if (max < 1000) {
            addVal = 50;
          } else if (max < 10000) {
            addVal = 500;
          } else if (max < 100000) {
            addVal = 1000;
          } else if (max < 1000000) {
            addVal = 5000;
          } else {
            addVal = 10000;
          }
          axis.max += addVal;
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 3,
      },
    },
  };

  let data_3_months = {
    // labels: labels_3months,
    labels: data?.month_labels,
    datasets: [
      {
        data: data?.month_labels.map((x) => data?.last_3_month_change[x]),
        // data: [2, 3, 2, 1.5],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor:
          theme === "dark"
            ? "rgba(217, 217, 217, 0.4)"
            : "rgba(74, 77, 78, 0.8)",
        lineTension: 0,
        borderWidth: 3,
        pointRadius: 4,
        pointBackgroundColor: "rgba(217, 217, 217, 1)",
        pointBorderColor:
          theme === "dark" ? "rgba(217, 217, 217, 1)" : "#000000",
        labels: data?.interval,
      },
    ],
  };

  return (
      <CustomChart
        type={"line"}
        data={data_3_months}
        options={options}
        theme={theme}
        // dependency={lineChartDependency}
      />
  );
};

export default KPILineChart;
